import React from "react";
import ContactFirstPage from "./ContactFirstPage";

function Contact() {
  return (
    <>
      <ContactFirstPage />
    </>
  );
}

export default Contact;