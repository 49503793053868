import React from "react";
import MainFirstPage from "./MainFirstPage";
import MainSecondPage from "./MainSecondPage";
import ContactUs from "./ContactUs";

function Main(){
    return(
        <>
            <MainFirstPage/>
            <MainSecondPage/>
            <ContactUs/>
        </>
    )
}

export default Main;