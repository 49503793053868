import React, { useState, useEffect } from "react";
import "./AdvantagesPage.css";
import AdvantageParagraph from "./AdvantageParagraph";
import { useParams } from "react-router-dom";
import Navbar from "./Navbar";
import NavbarContainer from "./NavbarContainer";
import ContactUs from "./ContactUs";

function AdvantagesPage() {
  const [navbarDisplay, setNavbarDisplay] = useState(window.innerWidth > 481);

  useEffect(() => {
    const checkScreen = () => {
      if (window.innerWidth <= 481 && navbarDisplay) {
        setNavbarDisplay(false);
      } else if (window.innerWidth > 481 && !navbarDisplay) {
        setNavbarDisplay(true);
      }
    };

    window.addEventListener("resize", checkScreen);

    // Check screen size on initial render
    checkScreen();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkScreen);
    };
  }, [navbarDisplay]);

  const { id } = useParams();

  // Define pages as an object for easier key-based access
  const pages = {
    page1: {
      maintitle: "ניהול כרטיס/תיק רכב",
      content: [
        {
          title: "מה כולל כרטיס ניהול רכב?",
          text: "פרטי רכב - דגם, בעלות (פרטית, ליסינג מימוני, תפעולי, השכרה), הסכמים, התקני דלק, אביזרים, תאריכי חידוש מעקב טיפולים וביקורות, מעקב תדלוקים, משיכת נתונים מדלקן ופזומט, תזכורות לחידוש רישיונות וביטוחים, מעקב אחר דוחות חניה, תאונות, כבישי אגרה, יומן רכב, בדיקות חורף, היסטורית נהגים, העברות בין נהגים רכבים חליפיים, סמכים סרוקים כגון רישיונות, פוליסות ביטוח, דוחות חניה ועוד",
        },
        {
          title: "פרטי רכב",
          text: 'אזור זה של תיק הרכב כולל את מספר הרישוי, קריאת ק"מ אחרונה ותאריכי עליה על הכביש. תום תוקף רישוי רישוי ותוקף ביטוח, ועוד. מאפייני הרכב בהנהלת חשבונות הכוללים מזהה כרטיס, הוצאות ואחוז הכרה בהוצאות מע"מ',
        },
        {
          title: "פרטי הסכם ליסינג",
          text: 'פרטי ההסכם מול חברות הליסינג כוללים את המחיר לפני מע"מ, את החלוקה לשלושת מרכיבי החיוב (שכירות אחזקה והמרכיב פטור), את מדד הבסיס הקובע ואת תאריכי ההסכם',
        },
        {
          title: "היסטוריית נהגים",
          text: "אזור זה בתיק הרכב מציג את היסטוריית הנהגים אליהם הרכב היה משויך בטרם נמסר לנהג הנוכחי. עבור כל שורת היסטוריה מוצג טווח התאריכים בהם השיוך היה תקף ושם הנהג. הקלקה על שם הנהג מאפשרת מעבר מהיר ישירות לתיק הנהג",
        },
        {
          title: "העברות רכב",
          text: "אזור זה מאפשר לתעד העברות קצרות מועד של הרכב בין נהגים ע״י תיעוד פרקי זמן קצרים בהם הרכב יצא מרשות הנהג הקבוע",
        },
        {
          title: "טיפולים וביקורות",
          text: "תיק הרכב מאפשר לתעד ולתייק פרטי טיפולים וביקורות אותם הרכב עובר ולהציף התראות של לוחות זמנים לקראת הטיפול או הביקורת הבאה",
        },
        {
          title: "תיעוד פרטי תאונות",
          text: "מרכיב זה בתיק הרכב מאפשר לקצין הבטיחות לתעד פרטי תאונות בהן היה הרכב מעורב ולתעד את סיכום תחקיר האירוע המתבצע עם נהג הרכב בעת התאונה",
        },
        {
          title: "רכבים חלופיים",
          text: 'מרכיב זה בתיק הרכב רלוונטי בעיקר עבור רכבי ליסינג ומאפשר לתעד תקופות בהם התקבל רכב חליפי. מידע זה משמש אח"כ בעת בקרת חשבוניות המתייחסות לחיובים בגין הרכב החליפי',
        },
      ],
    },
    page2: {
      maintitle: "ניהול הזמנות",
      content: [
        {
          title: "מערכת הזמנות",
          text: "מערכת זיו מאפשרת הוצאת הזמנות לספק הליסינג ולספקים הנוספים המקושרים לרכב ההזמנות נשלחות על פי פורמט ומחירון מוסכמים בהתאם למספר לוחית הרישוי, שולחת המערכת הזמנות ליתר הספקים כגון ספק הדלק, ספק השטיפה, כבישי אגרה וכדומ. באופן דומה תעדכן המערכת את הספקים על סגירת ההזמנה או ההסכם בעת החזרת רכב",
        },
        {
          title: "הנפקת הזמנות חדשות",
          text: "המערכת שולחת הזמנות חדשות לספק הליסינג בתבנית אימייל פורמלי עם כל המידע הדרוש לגבי הרכב המוזמן כולל פרטי הדגם, מחיר העסקה, פרטי הנהג, אתר אספקה רצוי ומועד צפוי לאספקה לקראת האספקה, לאחר עדכון במערכת של מספר הרישוי עבור הזמנה, תשלחנה באופן אוטומטי ההזמנות לספקים הנוספים",
        },
        {
          title: "סיום עסקאות",
          text: "בדומה למנגנון הנפקת הזמנות חדשות, מערכת זיו כוללת מנגנון לניהול תהליך סיום הסכמי ליסינג הודעות סיום עסקה נשלחות לכל הספקים הנוגעים לרכב",
        },
      ],
    },
    page3: {
      maintitle: "ניהול תיקי נהג",
      content: [
        {
          title: "פרטי נהג",
          text: "אזור זה של תיק הנהג מציג את פרטיו האישיים של הנהג, פרטי קשר, פרטי רישיון הנהיגה, השיוך הארגוני ואת הסטטוס בנוסף באזור זה ניתן לתייק קבצים סרוקים המשויכים לנהג",
        },
        {
          title: "היסטוריית רכבים",
          text: " התצוגה הראשית של תיק הנהג כוללת טבלה אשר מציגה את פרטי הרכבים אשר היו משויכים לנהג בהווה ובעבר כל רשומת רכב מציגה את פרטי הדגם, ספק הרכב ואת התאריכים בהם היה משויך הרכב לעובד",
        },
        {
          title: "פרטי נהגים נוספים",
          text: "בחלק זה המערכת מאפשרת לנהל את פרטי הנהגים המורשים הנוספים מקרב בני משפחתו של העובד .עבור נהג נוסף ניתן להגדיר שם, קרבה, תאריך לידה, מספר ותוקף רישיון נהיגה. וניתן לצרף סריקה של הרישיון התקף",
        },
        {
          title: "רשומות נוהל 6",
          text: "אזור זה מאפשר לתעד תלונות המתקבלות אודות הנהג במסגרת נוהל 6 בעת תיעוד רשומה חדשה ניתן לציין את תאריך ושעת האירוע בגינו התקבל הדיווח/התלונה, את פרטי הדיווח, את התגובה אשר התקבלה מהנהג לאור הדיווח ואת סיכום הטיפול במקרה",
        },
        {
          title: "עבירות תנועה",
          text: "אזור זה מאפשר לנהל מעקב אחר דוחות אשר התקבלו בגין עבירות תנועה של הנהג",
        },
        {
          title: "השתלמוית והכשרות",
          text: "בחלק זה המערכת מאפשרת לנהל מעקב אחר ההכשרות אותן קיבל הנהג בעת תיעוד רשומה חדשה ניתן לציין את תאריך ההשתלמות, את סוג ההשתלמות כמו כן ניתן להוסיף סיכום השתתפות ולצרף סריקה של התעודה/אסמכתא בגין ההשתתפות",
        },
      ],
    },
    page4: {
      maintitle: "ניהול ספקים",
      content: [
        {
          title: "מתן ערך מוסף לחברה",
          text: "המערכת מספקת מידע בעל ערך עצום לחברה כגון אילו רכבים צורכים הכי פחות דלק, מי הם הנהגים שיש לחייב על נסיעות בכבישי אגרה בסופ״ש, האם חיובי הליסינג שמשלמת החברה נכונים בהתאם להסכמים ולהצמדות ועוד",
        },
        {
          title: "מעקב צמוד מול ספקים",
          text: "המעקב הצמוד אל מול הספקים מאפשר להבין היכן ישנו בזבוז מיותר או הוצאות לא מוצדקות ובכך ניתן לחתוך בעלויות תפעול הצי ולחסוך לחברה הוצאות מיותרות",
        },
        {
          title: "מתן כלי התנהלות מול ספקים",
          text: "מערכת זיו מספקת כלים לניהול כל התהליכים מול הספקים. המערכת מאפשרת לנהל את הרשימה המפורטת של הספקים המאושרים, לנהל את המחירונים ואת ההזמנות מולם, לקלוט ולבקר את החשבוניות המתקבלות באופן שוטף, לנהל את תהליך הזיכוי של חיובים  שגויים ולהעביר את התנועות בגינן למערכת כספים",
        },
      ],
    },
    page5: {
      maintitle: "בקרת תקציב - כלי לחיסכון בעלויות",
      content: [
        {
          title: "מתן כלים נוחים לבקרה על תקציב הארגון",
          text: "המערכת מספקת לארגון כלים נוחים לקבלת מבט על ובקרה על תקציב הארגון המופנה לצי הרכבים",
        },
        {
          title: "מגוון נושאי בקרה",
          text: "מערכת זיו מכילה תחתיה מגוון רחב של נושאי בקרה כגון: הוצאות שכירות רבעוניות, הוצאות דלק רבעוניות, צריכת דלק ממוצעת, דוח חשבוניות ספקים ועוד",
        },
        {
          title: "סיכום המידע הפיננסי",
          text: "מערכת זיו מסכמת את המידע הפיננסי ומציגה אותו בדוח מסודר ונוח לשימוש, מה שנותן למנהלים ולמקבלי ההחלטות אפשרויות ביצוע ניתוחים פיננסיים בנושא עלויות ואחזקת הרכבים השונים",
        },
      ],
    },
    page6: {
      maintitle: "מערכת התראות",
      content: [
        {
          title: "הצגת התראות",
          text: "התרעות המערכת מאפשרות לקצין הבטיחות לזהות נקודות תורפה בעייתיות או נושאים שיש לטפל בהם עוד בטרם יהפכו לבעיות ממשיות. בכך מצליחה המערכת לחסוך כסף רב לחברה ומאפשרת לקצין הבטיחות להמשיך בניהול צי הרכב ללא בעיות והפרעות",
        },
        {
          title: "סוגי התראות",
          text: "המערכת כוללת התרעות בנושאים כמו טיפולים שונים שרכבי החברה צריכים לעבור, טסטים שיש לבצע, רישיונות שיש לחדש, נהגים בעייתיים שעושים נזק לרכב, תאונות, צורכים דלק יתר על המידה, צוברים קנסות ועוד",
        },
        {
          title: "התראות ניהוליות",
          text: 'כמו כן, המערכת מציגה התראות ניהוליות כמו ק"מ מצטברים, צריכות דלק ממוצעות, חריגה ממהירויות,  מצב טעינת מצבר ועד התראות חירום מהרכב כגון חום מנוע, סל"ד חריג, גניבת דלק ועוד',
        },
      ],
    },
  };

  // Retrieve the current page content based on the id
  const currentpage = pages[id] || { maintitle: "Page Not Found", content: [] };

  return (
    <div className="advantages-page">
      {navbarDisplay ? <Navbar /> : <NavbarContainer />}
      <div className="advantages-page-content">
        <h2>{currentpage.maintitle}</h2>
        <div className="advantage-paragraph-container">
          {currentpage.content.map((cont, index) => (
            <AdvantageParagraph
              key={index}
              title={cont.title}
              text={cont.text}
            />
          ))}
        </div>
      </div>

      <ContactUs />
    </div>
  );
}

export default AdvantagesPage;
